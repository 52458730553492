import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <div style={{paddingBottom:"100px"}}>
        <h3>Ubicación</h3>
        <Link to="https://goo.gl/maps/SDakeU1KJjJstiy49">
        <p>37 Avenida Norte 120 
        <br/>San Salvador, El Salvador</p>
        </Link>
        <h3>Teléfonoo</h3>
        <p>Oficina: <a href="tel:22606366">2260 6366</a></p>
      </div>

      <form 
      name="contact" 
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true">
      <p class="hidden">
      <label>
        Don’t fill this out if you’re human: <input name="bot-field" />
      </label>
      </p>
        <input placeholder="Su nombre..." type="text" name="name" />
        <input placeholder="Su email..." type="email" name="email" />
        <textarea
          placeholder="Su mensaje..."
          name="message"
          rows="5"
        ></textarea>
        <Button text="Enviar Mensaje" type="submit" />
      </form>
    </ContactStyles>
  )
}

export default Contact
